/**
 * コモンクラス
 * @constructor
 */
class Common {
  constructor() {

  }
  /**
   * コモン
   */
  init() {
        // PCのヘッダーメニューの動作
        var pcHaderAct = function() {
          var adressVal = location.href;
          if(adressVal.match(/acceleration/)) {
              classAdAct('acceleration');
          } else if(adressVal.match(/contest/)) {
              classAdAct('contest');
          } else if(adressVal.match(/achievements/)) {
            classAdAct('achievements');
          }
        }
        function classAdAct(selectObj){
          $('.l-header__link').removeClass('cr'); 
          $('.l-header__link__' + selectObj).addClass('cr'); 
        }
        
        pcHaderAct();


          $('#check').on('change', function(){
            if ($(this).is(':checked')) {
      
            //チェックが入ったら、送信ボタンを押せる
            $('#submitBtn, #submitBtn02').prop('disabled', false);
      
            } else {
      
            //チェックが入っていなかったら、送信ボタンを押せない
            $('#submitBtn, #submitBtn02').prop('disabled', true);
            }
          });

    }
}

export default Common;
