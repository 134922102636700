/**
 * サンプルクラス
 * @constructor
 */
class SpNav {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {
    $('.l-header__navToggle').on('click', function () {
      $('.l-header__navToggle, .l-header__navList').toggleClass('show');
  });
  $('.l-header__navList a').on('click', function () {
      $('.l-header__navToggle, .l-header__navList').removeClass('show');
  });
  }
};

export default SpNav;
